import React, {useContext, useEffect, useState} from "react"
import PropTypes from "prop-types"
import SearchResultsDesktop from "../../search/SearchResults"
import {MenuTitlesArray} from "../../../utility/Helper"
import DesktopTopHeader from "./DesktopTopHeader"
import DesktopMidHeader from "./DesktopMidHeader"
import DesktopBottomHeader from "./DesktopBottomHeader"
import StickyBar from "../StickyBar"
import {useRouter} from "next/router"
import TopNavTooltips from "./TopNavTooltips"
import StickyPdpNavigation from "../../bikeDetails/StickyPdpNavigation"
import DataContext from "../../../context/DataContext"
import SearchContext from "../../../context/SearchContext"

type Props = {
	absolute: boolean;
	menuTitles: MenuTitlesArray[];
	dropdownData?: {
		label: string
		items: { itemLabel: string; href: string }[]
	}[];
	customLanguageSwitcher: React.ReactNode
	hideNavBar:boolean
};

const DesktopNavbar: React.FC<Props> = ({
	absolute,
	menuTitles,
	dropdownData,
	customLanguageSwitcher,
	hideNavBar = false
}) => {
	const [isToggleOpen, setIsToggleOpen] = useState(false)
	const [isBackgroundOn, setIsBackgroundOn] = useState(false)
	const [addTopPadding, setAddTopPadding] = useState(false)
	const {termValue} = useContext(SearchContext)
	const router = useRouter()
	const closeMegaMenu = () => {
		setIsToggleOpen(false)
	}

	const isPDP = ["/produkt/[bike]", "/produit/[bike]"].includes(router.pathname)
	const {navigationObjects} = useContext(DataContext)
	useEffect(() => {
		if (isBackgroundOn) {
			document.body.style.overflow = "hidden"
		} else {
			document.body.style.overflow = "auto"
		}

		return () => {
			document.body.style.overflow = "auto"
		}
	}, [isBackgroundOn])

	useEffect(() => {
		setIsToggleOpen(false)
		setIsBackgroundOn(false)
	}, [router.asPath])
	return (
		<div className={`sticky-top d-none d-md-block w-100 ${hideNavBar && !isBackgroundOn && !termValue ? "slide-up-off-screen-17 " : "slide-down-from-top-17 top-2 end-0 start-0"}`}
			style={{minHeight: 165, zIndex: 9999}}>
			<div style={{zIndex: 999, borderBottomLeftRadius: "32px", borderBottomRightRadius: "32px"}}
				className={"d-none d-md-block bg-white w-100 shadow-sm position-relative "}>
				<TopNavTooltips />
				<div style={{maxWidth: 1890, margin: "0 auto"}}>
					<nav className={"bg-white position-sticky top-0 end-0 start-0 w-100"}
						style={{zIndex: absolute && !isToggleOpen && !isBackgroundOn ? 999 : 99999, borderBottomLeftRadius: "32px", borderBottomRightRadius: "32px", padding: `${addTopPadding ? "10px" : "0px"} 72px 0px 72px`}}>
						<DesktopMidHeader setIsBackgroundOn={setIsBackgroundOn}
							isBackgroundOn={isBackgroundOn}
							isFrontPage={absolute && !isToggleOpen && !isBackgroundOn}/>
						<div className="d-flex justify-content-center align-items-start col-auto flex-wrap">
							<DesktopBottomHeader
								dropdownData={dropdownData}
								closeMegaMenu={closeMegaMenu}
								isToggleOpen={isToggleOpen}
								setIsToggleOpen={setIsToggleOpen}
							/>
							<DesktopTopHeader
								menuTitles={menuTitles}
								dropdownData={dropdownData}
								customLanguageSwitcher={customLanguageSwitcher}
								closeMegaMenu={closeMegaMenu}/>
						</div>
					</nav>
				</div>
				<div className="d-none d-lg-flex justify-content-center ">
					<div style={{minWidth: "650px"}}
						className="row col-12 col-lg-9 justify-content-center">
						<SearchResultsDesktop setIsBackgroundOn={setIsBackgroundOn} />
					</div>
				</div>
			</div>
			<StickyBar isPDP={isPDP}
				setAddTopPadding={setAddTopPadding}
				hideStickyBar={hideNavBar}
				style={{marginTop: -30, borderBottomLeftRadius: 30, borderBottomRightRadius: 30, height: 75, justifyContent: "center", zIndex: -1}} />
			{navigationObjects && isPDP &&
				navigationObjects.bike.status !== "draft" &&
				navigationObjects.bike.stock_status !== "outofstock" ? <StickyPdpNavigation
					{...navigationObjects} /> : null}

			{(isBackgroundOn) &&
			<div className={"position-fixed top-0 start-0 bottom-0 end-0 bg-dark-transparent"}
				style={{
					zIndex: 200, width: "100%", height: "100vh"
				}}
				onClick={() => setIsBackgroundOn(false)}/>}
		</div>
	)
}

DesktopNavbar.propTypes = {
	absolute: PropTypes.bool.isRequired,
	menuTitles: PropTypes.array
}

export default DesktopNavbar

DesktopNavbar.defaultProps = {
	dropdownData: [
		{
			label: "Resources",
			items: [
				{itemLabel: "Blog", href: "/blog"},
				{itemLabel: "Showroom", href: "/brand-world/mybikeplan"},
				{itemLabel: "FAQ", href: "/faq"},
				{itemLabel: "Referral", href: "/referral"},
				{itemLabel: "Partnership", href: "/partnerprogramm"},
				{itemLabel: "Service-Partner", href: "/service-centers"},
				{itemLabel: "concept", href: "/konzept"},
				{itemLabel: "E-Bike Wiki", href: "/bike-verzeichnis"},
				{itemLabel: "Über uns", href: "/ueber-uns"}
			]
		}
	]
}
